<template>
  <div>
    <div class="page inner-container collection-detail">
      <section class="collection--detail--row collection--back">
        <button @click="backToCollectionList">
          {{ $t("collection.collection_back") }}
        </button>
      </section>

      <section class="collection--detail--row collection--description">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="title">
              <h2>
                {{ lang == "en" ? collection.title_en : collection.title_tc }}
              </h2>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <p>
              {{
                lang == "en"
                  ? collection.description_en
                  : collection.description_tc
              }}
            </p>
          </div>
        </div>
      </section>
      <section class="collection--detail--row collection--single--list">
        <div
          v-masonry=""
          transition-duration="0"
          item-selector=".item"
          column-width=".item__sizer"
          percent-position="true"
          gutter=".item__gutter"
        >
          <div class="item__sizer"></div>
          <div class="item__gutter"></div>
          <div
            v-masonry-tile
            v-for="(record, index) in collection.records"
            :key="index"
          >
            <SingleItem
              :name="lang == 'en' ? record.title_en : record.title_tc"
              :imageName="record.gallery[0].image"
              :hoverFunction="false"
              :relatedItem="false"
              :hoverColor="getSingleItemHoverColor"
              @click.native="openDetailModal(index, record.slug)"
            />
          </div>
        </div>
      </section>
    </div>
    <CollectionDetailModal
      ref="collectionModal"
      :collections="collection"
      :collectionDetailOpened="openDetail"
      @collectionDetailClosed="closeDetail"
      :collectionThumbsIndex="thumbsIndex"
    />
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
import SingleItem from "@/components/masonry/SingleItem";
import CollectionDetailModal from "@/components/common/CollectionDetailModal";
export default {
  name: "CollectionDetail",
  components: { SingleItem, CollectionDetailModal },
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      collection: {},
      openDetail: false,
      thumbsIndex: 0
    };
  },

  computed: {
    ...mapGetters("common", ["getSingleItemHoverColor"])
  },

  beforeMount() {
    this.apiCollectonDetail();
    setTimeout(() => {
      this.$redrawVueMasonry();
    }, 1500);
  },

  methods: {
    ...mapActions("common", ["setPreventScrolling"]),

    openDetailModal(index, slug) {
      console.log(index);
      console.log("OpenDetailModal");
      this.thumbsIndex = index;
      this.$refs.collectionModal.clickedRecord(this.thumbsIndex, slug);
      this.openDetail = !this.openDetail;
      this.setPreventScrolling(true);
    },

    closeDetail(value) {
      this.openDetail = value.modalState;
      this.setPreventScrolling(false);
    },

    backToCollectionList() {
      this.$router.push("/" + this.lang + "/collection");
    },

    async apiCollectonDetail() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/collection/${this.$route.params.slug}`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }

        const data = await response.json();

        this.collection = data;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  &__sizer {
    width: 48%;
    // width:calc(47% + 10px);
    // width: 224px;
    @include respond-to(screen-md) {
      // width: 15%;
      // width: 224px;
      width: 23.125%;
    }
    @include respond-to(screen-xl) {
      width: 15%;
      // width: 224px;
    }
  }
  &__gutter {
    width: 3.5%;
    @include respond-to(screen-md) {
      width: 2.5%;
      // width: 224px;
    }
    @include respond-to(screen-xl) {
      width: 2%;
      // width: 224px;
    }
  }
}

.page {
  padding-top: 20px;
  padding-bottom: 40px;
  @include respond-to(screen-md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.collection {
  &--description {
    font-family: "Graphik Compact", sans-serif;
    margin-bottom: 30px;
    h2 {
      font-size: 28px;
      font-weight: 300;
      // font-size: 44px;
      @include respond-to(screen-md) {
        font-size: 44px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 100;
      @include respond-to(screen-md) {
        font-size: 18px;
      }
    }
    .title {
      margin-bottom: 15px;
      @include respond-to(screen-md) {
        margin-bottom: 30px;
      }
    }
  }

  &--single {
    &--list {
      .single--item {
        padding-bottom: 30px;
      }
    }
  }
  &--back {
    padding-bottom: 30px;
    @include respond-to(screen-md) {
      padding-bottom: 30px;
    }
    button {
      font-family: "Graphik Compact", sans-serif;
      font-size: 16px;
      &::before {
        content: "";
        margin-right: 5px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-image: url("~@/assets/images/collection--back.svg");
        background-repeat: no-repeat;
      }
    }
  }
  &--description {
    margin-bottom: 40px;
    @include respond-to(screen-md) {
      margin-bottom: 100px;
    }
  }
}
.collection--back {
  button {
    opacity: 1;
    @include transition(all 300ms ease-in-out);
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
